var el_shade = document.getElementById('light-shade');
var el_words = document.getElementsByClassName('st-text');


// every 5 seconds, apply flicker
// loop through each word (key), to find nth child

var x = -1;
(function flicker(){
    x = (x + 1) % el_words.length;

    // remove it from all words
    for(var i=0; i < el_words.length; i++){
        el_words[i].classList.remove('is-animating');
    }
    el_shade.classList.remove('is-animating');
    setTimeout(function() {
        el_shade.classList.add('is-animating');
        el_words[x].classList.add('is-animating');
    }, 100);
    setTimeout(flicker, 3000);
})();